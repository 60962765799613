import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useSatelliteData } from "../context/SatelliteDataContext";
import "../assets/scss/views/DataVisualization.scss";
import FloatingCard from "./ui/cards/FloatingCard";
import MapIcon from "../assets/svg/components/map.svg";
import { Paper } from "@mui/material";
import LineChart from "./ui/charts/LineChart";
import PieChart from "./ui/charts/PieChart";
import CarouselCards from "./ui/carousel/CarouselCards";
import TransitionCard from "./ui/cards/TransitionCard";
import { useNotification, NotificationSeverity } from "../context/NotificationContext";

export default function DataVisualization({ data, onBack, onSave, theme }) {
  const { dataConvert, handleSaveProcessedData, uploadProgress } = useSatelliteData();
  const { showNotification } = useNotification();
  const [isSaving, setIsSaving] = useState(false);
  
  const totalExcesos =
    dataConvert.processedData?.globalStats?.excesosVelocidad?.length || 0;
    
  const processEventData = (vehicles) => {
    const eventData = {
      "Cambios de ruta": [],
      "Aceleración brusca": [],
      "Reporte por distancia": [],
      "Exceso de velocidad": [],
    };

    // Procesamos todos los vehículos
    Object.entries(vehicles).forEach(([key, events]) => {
      const [placa] = key.split("-");

      events.forEach((event) => {
        if (eventData[event.evento]) {
          eventData[event.evento].push({
            placa,
            direccion: event.direccion,
            latitud: event.latitud,
            longitud: event.longitud,
          });
        }
      });
    });

    // Agregamos conteos y organizamos la data final
    return Object.entries(eventData).map(([evento, datos]) => {
      const direccionesPorPlaca = datos.reduce((acc, curr) => {
        if (!acc[curr.placa]) {
          acc[curr.placa] = {
            direcciones: [],
            count: 0,
          };
        }
        acc[curr.placa].direcciones.push({
          direccion: curr.direccion,
          latitud: curr.latitud,
          longitud: curr.longitud,
        });
        acc[curr.placa].count++;
        return acc;
      }, {});

      return {
        evento,
        placasInfo: direccionesPorPlaca,
      };
    });
  };
  
  const eventData = useMemo(
    () => processEventData(dataConvert.processedData?.vehicles || {}),
    [dataConvert]
  );
  
  // Datos para el gráfico de pie
  const pieChartData = useMemo(() => {
    const { processedData } = dataConvert;
    const eventCounts = {
      "Cambios de ruta": 0,
      "Aceleración brusca": 0,
      "Reporte por distancia": 0,
      "Exceso de velocidad": 0,
    };

    // Obtener el mes actual de los datos
    const months = processedData?.months || {};
    const currentMonth = Object.keys(months)[0];

    // Procesar eventos por vehículo
    Object.entries(processedData?.vehicles || {}).forEach(([_, events]) => {
      events.forEach((event) => {
        switch (event.evento) {
          case "Cambios de ruta":
            eventCounts["Cambios de ruta"]++;
            break;
          case "Aceleración brusca":
            eventCounts["Aceleración brusca"]++;
            break;
          case "Reporte por distancia":
            eventCounts["Reporte por distancia"]++;
            break;
          case "Exceso de velocidad":
            eventCounts["Exceso de velocidad"]++;
            break;
          default:
            break;
        }
      });
    });

    // Convertir a formato requerido por ECharts
    const formattedData = Object.entries(eventCounts)
      .filter(([_, value]) => value > 0)
      .map(([name, value]) => ({ name, value }));

    return {
      data: formattedData,
      centerText: currentMonth || "Sin fecha disponible",
    };
  }, [dataConvert]);
  
  // Datos para la grafica line
  const chartData = useMemo(() => {
    const { processedData } = dataConvert;
    const months = processedData?.months || {};
    const currentMonth = Object.keys(months)[0]; // Tomamos el primer mes disponible

    if (!currentMonth) return { xAxisData: [], seriesData: [] };

    // Crear array de días del mes
    const daysInMonth = new Date(
      parseInt(currentMonth.split("-")[0]),
      parseInt(currentMonth.split("-")[1]),
      0
    ).getDate();
    const xAxisData = Array.from({ length: daysInMonth }, (_, i) => `${i + 1}`);

    // Inicializar contadores por día
    const tripsByDay = Array(daysInMonth).fill(0);
    const speedExcessByDay = Array(daysInMonth).fill(0);
    const routeChangesByDay = Array(daysInMonth).fill(0);

    // Procesar viajes
    Object.entries(processedData.trips || {}).forEach(([_, trips]) => {
      trips.forEach((trip) => {
        const day = new Date(trip.puntoOrigen?.fecha).getDate();
        tripsByDay[day - 1]++;
      });
    });

    // Procesar eventos por vehículo
    Object.entries(processedData.vehicles || {}).forEach(([key, events]) => {
      events.forEach((event) => {
        const day = new Date(event.fecha).getDate();
        if (event.evento === "Exceso de velocidad") {
          speedExcessByDay[day - 1]++;
        } else if (event.evento === "Reporte por distancia") {
          routeChangesByDay[day - 1]++;
        }
      });
    });

    const seriesData = [
      {
        name: "Viajes totales",
        data: tripsByDay,
      },
      {
        name: "Excesos de velocidad",
        data: speedExcessByDay,
      },
      {
        name: "Cambios de ruta",
        data: routeChangesByDay,
      },
    ];

    return { xAxisData, seriesData };
  }, [dataConvert]);
  
  const cardColors = {
    viajes: theme === 'light' ? "purple" : "theme1",
    registros: theme === 'light' ? "blue" : "theme1", 
    vehiculos: theme === 'light' ? "teal" : "theme2",
    excesos: theme === 'light' ? "green" : "theme3"
  };
  
  // Guardar Datos con manejo de errores y estado de carga
  const handleSaveData = async () => {
    try {
      setIsSaving(true);
      
      if (!dataConvert?.processedData?.vehicles || 
          Object.keys(dataConvert.processedData.vehicles).length === 0) {
        showNotification({
          message: "No hay datos para guardar",
          severity: NotificationSeverity.WARNING
        });
        setIsSaving(false);
        return;
      }
      
      await handleSaveProcessedData();
      
      // La notificación de éxito se maneja en el modal automáticamente
    } catch (error) {
      console.error("Error al guardar los datos:", error);
      showNotification({
        message: `Error al guardar: ${error.message || "Error desconocido"}`,
        severity: NotificationSeverity.ERROR
      });
    } finally {
      setIsSaving(false);
    }
  };

  // Verificar si hay archivos inválidos
  const hasInvalidFiles = useMemo(() => {
    return dataConvert?.invalidFiles && dataConvert.invalidFiles.length > 0;
  }, [dataConvert]);

  // Si hay archivos inválidos, mostrar notificación
  React.useEffect(() => {
    if (hasInvalidFiles) {
      const invalidCount = dataConvert.invalidFiles.length;
      showNotification({
        message: `${invalidCount} ${invalidCount === 1 ? 'archivo no pudo' : 'archivos no pudieron'} ser procesado${invalidCount === 1 ? '' : 's'}`,
        severity: NotificationSeverity.WARNING
      });
    }
  }, [hasInvalidFiles, dataConvert.invalidFiles, showNotification]);
  
  // Verificar el estado de progreso
  React.useEffect(() => {
    if (uploadProgress.status === 'error') {
      showNotification({
        message: uploadProgress.message || "Error en el proceso",
        severity: NotificationSeverity.ERROR
      });
    }
  }, [uploadProgress, showNotification]);
  
  return (
    <div className={`GM__${theme}-data-visualization`}>
      <div className={`GM__${theme}-cards-data`}>
        <div className={`GM__${theme}-card`}>
          <FloatingCard
            title="Total de registros"
            value={dataConvert.processedData?.globalStats?.totalRecords}
            icon={MapIcon}
            colorTheme={cardColors.registros}
          />
        </div>
        <div className={`GM__${theme}-card`}>
          <FloatingCard
            title="Vehículos totales"
            value={dataConvert.processedData?.globalStats?.vehiculosTotales}
            icon={MapIcon}
            colorTheme={cardColors.vehiculos}
          />
        </div>
        <div className={`GM__${theme}-card`}>
          <FloatingCard
            title="Excesos de velocidad"
            value={totalExcesos}
            icon={MapIcon}
            colorTheme={cardColors.excesos}
          />
        </div>
      </div>
      
      <div className={`GM__${theme}-charts`}>
        <div className={`GM__${theme}-chart`}>
          <Paper elevation={3} className={`GM__${theme}-chart-paper`}>
            <LineChart
              title="Análisis de Eventos por Día"
              xAxisData={chartData.xAxisData}
              seriesData={chartData.seriesData}
              theme={theme}
              height="400px"
            />
          </Paper>
        </div>
        <div className={`GM__${theme}-chart`}>
          <Paper elevation={3} className={`GM__${theme}-chart-paper`}>
            <PieChart
              title="Distribución de Tipos de Eventos"
              data={pieChartData.data}
              theme={theme}
              height="400px"
              centerText={pieChartData.centerText}
            />
          </Paper>
        </div>
      </div>
      
      <div className={`GM__${theme}-events`}>
        <div className={`GM__${theme}-carousel`}>
          <CarouselCards 
            itemsPerView={3} 
            autoRun={true} 
            autoRunInterval={5000}
            theme={theme}
          >
            {eventData.map((eventInfo, index) => {
              // Colores según índice
              const borderColor = (() => {
                switch (index) {
                  case 0: return theme === 'light' ? "#d63939" : "#af5e5e";
                  case 1: return theme === 'light' ? "#39d639" : "#5eaf5e";
                  case 2: return theme === 'light' ? "#3939d6" : "#5e5eaf";
                  default: return theme === 'light' ? "#d639d6" : "#af5eaf";
                }
              })();

              return (
                <TransitionCard
                  key={`${eventInfo.evento}-${index}`} 
                  evento={eventInfo.evento}
                  placasInfo={eventInfo.placasInfo}
                  theme={theme}
                  borderColor={borderColor}
                  autoRunInterval={4000}
                />
              );
            })}
          </CarouselCards>
        </div>
      </div>

      <div className={`GM__${theme}-actions`}>
        <button 
          className={`GM__${theme}-btn GM__${theme}-btn-outline`} 
          onClick={onBack}
          disabled={isSaving}
        >
          Atrás
        </button>
        <button 
          className={`GM__${theme}-btn GM__${theme}-btn-primary`} 
          onClick={handleSaveData}
          disabled={isSaving || uploadProgress.status === 'processing'}
        >
          {isSaving || uploadProgress.status === 'processing' ? (
            <span className="btn-loading">
              Guardando datos
              <span className="loading-dots"></span>
            </span>
          ) : (
            'Guardar Datos'
          )}
        </button>
      </div>
    </div>
  );
}

DataVisualization.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onBack: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  theme: PropTypes.oneOf(['light', 'dark']),
};