import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useAuth } from './AuthContext';
import { useNotification } from './NotificationContext';

const SocketContext = createContext(null);

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { user, token, logout, updateToken } = useAuth();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (user && token) {
      const socketUrl = process.env.REACT_APP_API_URL_IO_PRODUCTION;//'http://localhost:4001'

      const newSocket = io(socketUrl, {
        transports: ['websocket', 'polling'],
        auth: {
          token: token
        },
        query: {
          userId: user.id,
          userEmail: user.email,
          userName: `${user.first_name.trim()} ${user.first_last_name.trim()} ${user.second_last_name}`.trim(),
          userRole: user.role_name,
          documentNumber: user.document_number
        },
      });

      newSocket.on('connect', () => {
        console.log('Socket conectado:', newSocket.id);
        setSocket(newSocket);
        
        showNotification({
          message: `${user.first_name.trim()} se ha conectado al sistema`,
          severity: 'success',
          autoHideDuration: 4000,
        });
      });

      // Manejar renovación de token
      newSocket.on('token_refresh', ({ token: newToken }) => {
        console.log('Token renovado automáticamente');
        updateToken(newToken);
      });

      // Manejar token expirado o inválido
      newSocket.on('authentication_error', (error) => {
        console.error('Error de autenticación:', error);
        showNotification({
          message: 'Sesión expirada. Por favor, inicie sesión nuevamente.',
          severity: 'error',
          autoHideDuration: 6000,
        });
        logout();
      });

      newSocket.on('disconnect', (reason) => {
        console.log('Socket desconectado:', reason);
        setSocket(null);
        
        // Si el socket se desconecta por error de autenticación
        if (reason === 'io server disconnect') {
          showNotification({
            message: 'Sesión expirada o inválida',
            severity: 'error',
            autoHideDuration: 4000,
          });
          logout();
        } else {
          showNotification({
            message: `${user.first_name.trim()} se ha desconectado del sistema`,
            severity: 'warning',
            autoHideDuration: 4000,
          });
        }
      });

      newSocket.on('connect_error', (error) => {
        console.error('Error de conexión:', error);
        
        if (error.message.includes('Autenticación inválida') || 
            error.message.includes('token') || 
            error.message.includes('Token expirado')) {
          showNotification({
            message: 'Sesión expirada. Por favor, inicie sesión nuevamente.',
            severity: 'error',
            autoHideDuration: 4000,
          });
          logout();
        } else {
          showNotification({
            message: 'Error al conectar con el servidor de tiempo real',
            severity: 'error',
            autoHideDuration: 4000,
          });
        }
      });

      return () => {
        if (newSocket) {
          newSocket.disconnect();
        }
      };
    }
    
    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
    };
  }, [user, token, logout, updateToken, showNotification]); 

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};