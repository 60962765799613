import React, { useMemo, useState } from "react";
import { Chip, IconButton, Button, Paper } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/es";
import MapIcon from "../../assets/svg/components/map.svg";
import VehicleIcon from "../../assets/svg/components/vehicle.svg";
import AlertIcon from "../../assets/svg/components/alert.svg";
import DownloadIcon from "@mui/icons-material/Download";
import "../../assets/scss/views/DashboardAdmin.scss";
import FloatingCard from "../../components/ui/cards/FloatingCard";
import { useLayout } from "../../context/LayoutContext";
import { useDailyAnalytics } from "../../hooks/useReactQuery";
import LineChart from "../../components/ui/charts/LineChart";
import PieChart from "../../components/ui/charts/PieChart";
import TransitionCard from "../../components/ui/cards/TransitionCard";
import CarouselCards from "../../components/ui/carousel/CarouselCards";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useDownload } from "../../context/DownloadContext";

const DashboardAdmin = () => {
  const { selectedDate, theme } = useLayout(); // Asegúrate de que theme está disponible desde el contexto
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    data: dailyAnalytics,
    isLoading,
    isError,
  } = useDailyAnalytics(selectedDate);
  const { 
    handleDownloadYearAndMonth,
    handleDownloadYear,
  } = useDownload();

  // No hay cambios en las funciones de cálculo y procesamiento de datos
  const calculateTotalTrips = (data) => {
    if (!data?.processedData?.trips) return 0;
    return Object.values(data.processedData.trips).reduce((total, trips) => {
      return total + trips.length;
    }, 0);
  };

  const calculateSpeedViolations = (data) => {
    if (!data?.processedData?.trips) return 0;
    
    return Object.values(data.processedData.trips).reduce((total, trips) => {
      return total + trips.reduce((tripTotal, trip) => {
        return tripTotal + (trip.criticalViolationsCount || 0);
      }, 0);
    }, 0);
  };

  const calculateSpeedExcess = (data) => {
    if (!data?.processedData?.trips) return 0;
    
    return Object.values(data.processedData.trips).reduce((total, trips) => {
      return total + trips.reduce((tripTotal, trip) => {
        return tripTotal + (trip.overspeedCount || 0);
      }, 0);
    }, 0);
  };

  const totalTrips = dailyAnalytics ? calculateTotalTrips(dailyAnalytics) : 0;
  const totalSpeedViolations = dailyAnalytics ? calculateSpeedViolations(dailyAnalytics) : 0;
  const totalSpeedExcess = dailyAnalytics ? calculateSpeedExcess(dailyAnalytics) : 0;

  // Definición de colores de tarjetas según el tema
  const cardColors = {
    viajes: theme === 'light' ? "blue" : "theme1",
    registros: theme === 'light' ? "purple" : "theme2", 
    violaciones: theme === 'light' ? "red" : "theme4",
    excesos: theme === 'light' ? "red" : "theme4"
  };

  // Sin cambios en el resto de funciones de procesamiento de datos
  const calculateDateRange = (trips) => {
    let dates = [];
    
    Object.values(trips).forEach(vehicleTrips => {
      vehicleTrips.forEach(trip => {
        if (trip.origin?.dateTime) {
          dates.push(new Date(trip.origin.dateTime));
        }
        if (trip.destination?.dateTime) {
          dates.push(new Date(trip.destination.dateTime));
        }
      });
    });
  
    if (dates.length === 0) {
      return { minDate: null, maxDate: null };
    }
  
    dates.sort((a, b) => a - b);
    const minDate = new Date(dates[0]);
    const maxDate = new Date(dates[dates.length - 1]);
  
    const marginDays = 2;
    minDate.setDate(minDate.getDate() - marginDays);
    maxDate.setDate(maxDate.getDate() + marginDays);
  
    return { minDate, maxDate };
  };

  const generateDateAxis = (minDate, maxDate) => {
    const dates = [];
    const currentDate = new Date(minDate);
    
    while (currentDate <= maxDate) {
      dates.push(dayjs(currentDate).format('DD/MM'));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    
    return dates;
  };

  const chartData = useMemo(() => {
    if (!dailyAnalytics?.processedData?.trips) {
      return {
        xAxisData: [],
        seriesData: []
      };
    }
  
    const trips = dailyAnalytics.processedData.trips;
    
    const { minDate, maxDate } = calculateDateRange(trips);
    const xAxisData = generateDateAxis(minDate, maxDate);
    
    const daysCount = xAxisData.length;
    const speedViolations = new Array(daysCount).fill(0);
    const speedExcess = new Array(daysCount).fill(0);
    const totalTripsPerDay = new Array(daysCount).fill(0);
  
    Object.values(trips).forEach(vehicleTrips => {
      vehicleTrips.forEach(trip => {
        const tripDate = new Date(trip.origin?.dateTime);
        const dayIndex = Math.floor((tripDate - minDate) / (1000 * 60 * 60 * 24));
        
        if (dayIndex >= 0 && dayIndex < daysCount) {
          totalTripsPerDay[dayIndex]++;
          speedViolations[dayIndex] += trip.criticalViolationsCount || 0;
          speedExcess[dayIndex] += trip.overspeedCount || 0;
        }
      });
    });
  
    const seriesData = [
      {
        name: 'Violaciones de velocidad',
        data: speedViolations,
      },
      {
        name: 'Excesos de velocidad',
        data: speedExcess,
      },
      {
        name: 'Viajes totales',
        data: totalTripsPerDay,
      }
    ];
  
    return { xAxisData, seriesData };
  }, [dailyAnalytics]);

  const pieChartData = useMemo(() => {
    const trips = dailyAnalytics?.processedData?.trips;
    if (!trips) {
      return {
        data: [],
        centerText: "Sin fecha disponible"
      };
    }

    const eventCounts = {
      "Violaciones críticas": 0,
      "Excesos de velocidad": 0,
      "Aceleraciones bruscas": 0,
      "Eventos de bloqueo": 0,
    };

    Object.values(trips).forEach(vehicleTrips => {
      vehicleTrips.forEach(trip => {
        eventCounts["Violaciones críticas"] += trip.criticalViolationsCount || 0;
        eventCounts["Excesos de velocidad"] += trip.overspeedCount || 0;
        eventCounts["Aceleraciones bruscas"] += trip.harshAccelerationCount || 0;
        eventCounts["Eventos de bloqueo"] += trip.jammingCount || 0;
      });
    });

    const formattedData = Object.entries(eventCounts)
      .filter(([_, value]) => value > 0)
      .map(([name, value]) => ({ name, value }));

    return {
      data: formattedData,
      centerText: dayjs(selectedDate).format("MMMM YYYY")
    };
  }, [dailyAnalytics, selectedDate]);

  const processEventData = (trips) => {
    const eventData = {
      "Violaciones críticas": {},
      "Excesos de velocidad": {},
      "Aceleraciones bruscas": {},
      "Eventos de bloqueo": {},
    };

    Object.entries(trips).forEach(([placa, vehicleTrips]) => {
      vehicleTrips.forEach(trip => {
        // Procesar violaciones críticas
        if (trip.criticalViolationsCount > 0) {
          if (!eventData["Violaciones críticas"][placa]) {
            eventData["Violaciones críticas"][placa] = {
              direcciones: [],
              count: 0
            };
          }
          trip.criticalViolations.forEach(violation => {
            eventData["Violaciones críticas"][placa].direcciones.push({
              direccion: `${violation.lat}, ${violation.lon}`,
              latitud: violation.lat,
              longitud: violation.lon
            });
          });
          eventData["Violaciones críticas"][placa].count += trip.criticalViolationsCount;
        }

        // Procesar excesos de velocidad
        if (trip.overspeedCount > 0) {
          if (!eventData["Excesos de velocidad"][placa]) {
            eventData["Excesos de velocidad"][placa] = {
              direcciones: [],
              count: 0
            };
          }
          trip.overspeedEvents.forEach(event => {
            eventData["Excesos de velocidad"][placa].direcciones.push({
              direccion: `${event.lat}, ${event.lon}`,
              latitud: event.lat,
              longitud: event.lon
            });
          });
          eventData["Excesos de velocidad"][placa].count += trip.overspeedCount;
        }

        // Similar para otros eventos...
      });
    });

    return Object.entries(eventData).map(([evento, placasInfo]) => ({
      evento,
      placasInfo
    }));
  };

  const eventData = useMemo(
    () => processEventData(dailyAnalytics?.processedData?.trips || {}),
    [dailyAnalytics]
  );

  const handleDownload = async () => {
    try {
      alert("¡De momento no se ha generado las plantillas, estamos en el proceso!");
    } catch (error) {
      console.error("Error al descargar:", error);
    }
  };

  const onDownloadYearAndMonth = async () => {
    try {
      await handleDownloadYearAndMonth(selectedDate);
    } catch (error) {
      // Manejar el error si es necesario en el componente
    }
  };

  const onDownloadYear = async () => {
    try {
      await handleDownloadYear(selectedDate);
    } catch (error) {
      // Manejar el error si es necesario en el componente
    }
  };

  // Estilo para el loader (no cambia)
  const loaderStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  };

  const spinnerStyles = {
    width: "50px",
    height: "50px",
    border: "5px solid #f3f3f3",
    borderTop: "5px solid #3498db",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  };

  // Aquí está la parte principal actualizada con el soporte para temas
  return (
    <div className={`GM__${theme}-dashboard-container`}>
      {isDownloading && (
        <div style={loaderStyles}>
          <div style={spinnerStyles}></div>
          <span style={{ color: "white", marginTop: "10px" }}>
            Generando el archivo...
          </span>
        </div>
      )}
      <div className="dashboard-header">
        <h1 className="dashboard-title">Dashboard</h1>
      </div>
      {isLoading && (
        <LoadingAnimation/>
      )}
      <div className="cards-data">
        <div className="card">
          <FloatingCard
            title="Viajes en el mes"
            value={totalTrips}
            icon={MapIcon}
            colorTheme={cardColors.viajes}
          />
        </div>
        <div className="card">
          <FloatingCard
            title="Total de registros"
            value={dailyAnalytics?.processedData?.averageRecords || 0}
            icon={VehicleIcon}
            colorTheme={cardColors.registros}
          />
        </div>
        <div className="card">
          <FloatingCard
            title="Violaciones de puntos críticos"
            value={totalSpeedViolations}
            icon={AlertIcon}
            colorTheme={cardColors.violaciones}
          />
        </div>
        <div className="card">
          <FloatingCard
            title="Excesos de velocidad"
            value={totalSpeedExcess}
            icon={AlertIcon}
            colorTheme={cardColors.excesos}
          />
        </div>
      </div>
      <div className="charts">
        <div className="chart">
          <Paper elevation={3} style={{ padding: "1rem", height: "100%" }}>
            <LineChart
              title="Análisis de Eventos Diarios"
              xAxisData={chartData.xAxisData}
              seriesData={chartData.seriesData}
              theme={theme}
              height="400px"
            />
          </Paper>
        </div>
        <div className="chart">
          <Paper elevation={3} style={{ padding: "1rem", height: "100%" }}>
            <PieChart
              title="Distribución de Tipos de Eventos"
              data={pieChartData.data}
              theme={theme}
              height="400px"
              centerText={pieChartData.centerText}
            />
          </Paper>
        </div>
      </div>
      <div className="dashboard-download-section">
        <div className="download-button">
          <span>Descargar</span>
          <Chip
            label={`${dayjs(selectedDate).format("MMMM YYYY")}`}
            variant="outlined"
          />
          <IconButton color="primary" onClick={onDownloadYearAndMonth}>
            <DownloadIcon />
          </IconButton>
        </div>
        <Button
          variant="contained"
          className="download-period-btn"
          onClick={handleDownload}
        >
          Descargar Periodo
        </Button>
        <Button
          variant="contained"
          className="download-year-btn"
          onClick={onDownloadYear}
        >
          Descargar Año
        </Button>
      </div>
      <div className="events">
        <div className="carousel">
          <CarouselCards 
            itemsPerView={3} 
            autoRun={true} 
            autoRunInterval={5000}
            theme={theme} // Pasar el tema al componente CarouselCards
          >
            {eventData.map((eventInfo, index) => {
              // Colores según índice y tema
              const borderColor = (() => {
                if (theme === 'light') {
                  switch (index) {
                    case 0: return "#d63939";
                    case 1: return "#39d639";
                    case 2: return "#3939d6";
                    default: return "#d639d6";
                  }
                } else {
                  switch (index) {
                    case 0: return "#af5e5e";
                    case 1: return "#5eaf5e";
                    case 2: return "#5e5eaf";
                    default: return "#af5eaf";
                  }
                }
              })();

              return (
                <TransitionCard
                  key={`${eventInfo.evento}-${index}`}
                  evento={eventInfo.evento}
                  placasInfo={eventInfo.placasInfo}
                  theme={theme}
                  borderColor={borderColor}
                  autoRunInterval={4000}
                />
              );
            })}
          </CarouselCards>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;