import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { satelliteService } from "../service";
import ResponseModal from "../components/ui/modals/ResponseModal";
import { VisibilityManager } from "../utils/visibilityManager";
import { useLayout } from "./LayoutContext";
export const SatelliteDataContext = createContext();

export const useSatelliteData = () => useContext(SatelliteDataContext);

export const SatelliteDataProvider = ({ children }) => {
  const { theme } = useLayout();
  const [files, setFiles] = useState([]);
  const [dataConvert, setDataConvert] = useState(null);
  //console.log(dataConvert);
  const [activeStep, setActiveStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalResponse, setModalResponse] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({
    percentage: 0,
    currentFile: null,
    totalFiles: 0,
    estimatedTimeRemaining: 0,
    status: "idle", // 'idle' | 'processing' | 'complete' | 'error'
    operation: null, // 'upload' | 'save' | null
    message: "",
    completedAt: null,
    autoReset: true,
  });
  const visibilityManager = useMemo(() => new VisibilityManager(), []);

  useEffect(() => {
    return () => visibilityManager.destroy();
  }, [visibilityManager]);
  const resetProgress = useCallback(() => {
    setUploadProgress({
      percentage: 0,
      currentFile: null,
      totalFiles: 0,
      estimatedTimeRemaining: 0,
      status: "idle",
      operation: null,
      message: "",
      completedAt: null,
      autoReset: true,
    });
  }, []);
  // Efecto para manejar el reseteo automático
  useEffect(() => {
    let resetTimer;

    if (uploadProgress.status === "complete" && uploadProgress.autoReset) {
      resetTimer = setTimeout(() => {
        resetProgress();
      }, 6000); // 3 segundos para el reseteo
    }

    return () => {
      if (resetTimer) {
        clearTimeout(resetTimer);
      }
    };
  }, [uploadProgress.status, uploadProgress.autoReset, resetProgress]);
  const handleSaveProcessedData = async () => {
    try {
      if (!dataConvert?.processedData?.vehicles) {
        const errorMsg = "No hay datos para procesar";
        setModalResponse({
          success: false,
          message: errorMsg,
        });
        setIsModalOpen(true);
        return Promise.reject(new Error(errorMsg));
      }

      // Inicializar el estado de carga
      setUploadProgress({
        percentage: 0,
        currentFile: "Iniciando proceso",
        totalFiles: 0,
        estimatedTimeRemaining: 0,
        status: "processing",
        operation: "save",
        message: "Iniciando guardado de datos",
        autoReset: false,
      });

      const savedResponse = await satelliteService.saveProcessedData(
        dataConvert.processedData,
        (progress) => {
          setUploadProgress((prev) => ({
            ...prev,
            percentage: progress.percentage,
            currentFile: progress.currentChunk,
            totalFiles: progress.totalChunks,
            status: "processing",
            message: `Procesando chunk ${progress.currentChunk}/${progress.totalChunks}`,
          }));
        }
      );

      // Actualizar estado al completar
      setUploadProgress((prev) => ({
        ...prev,
        percentage: 100,
        status: "complete",
        message: "Proceso completado exitosamente",
      }));

      // Formatear respuesta para el modal
      const formattedResponse = {
        success: true,
        message: savedResponse.success
          ? "Fragmento guardado exitosamente"
          : "Hubo problemas al guardar algunos datos",
        data: {
          processedArchives: savedResponse.results || [],
          duplicateRecords: savedResponse.data?.duplicateRecords || {},
        },
      };

      setModalResponse(formattedResponse);
      setIsModalOpen(true);

      // Resetear después de un breve delay
      setTimeout(() => {
        resetProgress();
        if (savedResponse.success) {
          setFiles([]);
          setActiveStep(0);
        }
      }, 2000);

      return savedResponse;
    } catch (error) {
      console.error("Error al guardar datos:", error);

      setUploadProgress((prev) => ({
        ...prev,
        status: "error",
        message: `Error: ${error.message}`,
      }));

      // Mostrar error en el modal
      setModalResponse({
        success: false,
        message: `Error al guardar los datos: ${
          error.message || "Error desconocido"
        }`,
        invalidFiles: [],
      });
      setIsModalOpen(true);

      // Resetear el estado después de un tiempo
      setTimeout(() => {
        resetProgress();
      }, 3000);

      return Promise.reject(error);
    }
  };
  const GenerateDataJSON = async (files) => {
    const batchSize = 5; // Tamaño máximo de la tanda
    const totalBatches = Math.ceil(files.length / batchSize);
    const totalFiles = files.length;

    let processedFiles = [];
    let invalidFiles = [];
    let globalProcessedData = {
      files: [],
      globalStats: {},
      months: {},
      trips: {},
      vehicles: {},
    };
    let errorOccurred = false;

    // Inicializar el estado de progreso
    setUploadProgress({
      percentage: 0,
      currentFile: "Iniciando proceso",
      totalFiles: totalFiles,
      estimatedTimeRemaining: 0,
      status: "processing",
      operation: "upload",
      message: "Iniciando procesamiento de archivos",
      autoReset: false,
    });

    const startTime = Date.now();

    for (let i = 0; i < totalBatches; i++) {
      const batchFiles = files.slice(i * batchSize, (i + 1) * batchSize);
      const formData = new FormData();

      batchFiles.forEach((file, index) => {
        formData.append(`file_${index}`, file.file);
      });

      try {
        // Calcular el progreso actual
        const processedCount = i * batchSize;
        const currentPercentage = Math.min(
          Math.round((processedCount / totalFiles) * 100),
          99 // Mantener en 99% hasta que termine completamente
        );

        // Calcular tiempo estimado restante
        const elapsedTime = Date.now() - startTime;
        const estimatedTotalTime = (elapsedTime / currentPercentage) * 100;
        const estimatedTimeRemaining = Math.max(
          0,
          Math.round((estimatedTotalTime - elapsedTime) / 1000)
        );

        // Actualizar el estado de progreso
        setUploadProgress((prev) => ({
          ...prev,
          percentage: currentPercentage,
          currentFile: `Procesando lote ${i + 1} de ${totalBatches}`,
          totalFiles: totalFiles,
          estimatedTimeRemaining: estimatedTimeRemaining,
          status: "processing",
          message: `Procesando ${batchFiles.length} archivos (${
            processedCount + 1
          }-${Math.min(
            processedCount + batchSize,
            totalFiles
          )} de ${totalFiles})`,
        }));

        const response = await satelliteService.uploadExcelFile(formData);

        if (response && response.status === "success") {
          processedFiles.push(...response.response);
          globalProcessedData.files.push(
            ...(response.processedData.files || [])
          );
          globalProcessedData.globalStats = {
            ...globalProcessedData.globalStats,
            ...response.processedData.globalStats,
          };
          globalProcessedData.months = {
            ...globalProcessedData.months,
            ...response.processedData.months,
          };
          globalProcessedData.trips = {
            ...globalProcessedData.trips,
            ...response.processedData.trips,
          };
          globalProcessedData.vehicles = {
            ...globalProcessedData.vehicles,
            ...response.processedData.vehicles,
          };
        } else {
          console.error("Archivo inválido:", response);
          invalidFiles.push(...batchFiles);
        }
      } catch (error) {
        console.error("Error procesando la tanda de archivos:", error);
        setUploadProgress((prev) => ({
          ...prev,
          status: "error",
          message: `Error: ${error.message}`,
          autoReset: true,
        }));
        errorOccurred = true;
        break;
      }
    }

    if (errorOccurred) {
      throw new Error("Error al procesar las tandas de archivos");
    }

    // Actualizar el progreso al 100% al finalizar
    setUploadProgress((prev) => ({
      ...prev,
      percentage: 100,
      currentFile: "Proceso completado",
      status: "complete",
      message: "Procesamiento de archivos completado exitosamente",
      completedAt: new Date().toISOString(),
      autoReset: true,
    }));

    const finalResponse = {
      status: "success",
      response: processedFiles,
      invalidFiles,
      processedData: globalProcessedData,
      translate:
        processedFiles.length > 1
          ? "SUCCESS_MULTIPLE_CONVERSION"
          : "SUCCESS_CONVERSION",
      timestamp: new Date().toISOString(),
    };

    setDataConvert(finalResponse);
    return finalResponse;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalResponse(null);
  };
  const contextValue = useMemo(
    () => ({
      dataConvert,
      GenerateDataJSON,
      uploadProgress,
      activeStep,
      setActiveStep,
      handleSaveProcessedData,
      files,
      setFiles,
      resetProgress,
    }),
    [dataConvert, uploadProgress, activeStep, files, resetProgress]
  );

  return (
    <SatelliteDataContext.Provider value={contextValue}>
      {children}
      <ResponseModal
        open={isModalOpen}
        onClose={handleCloseModal}
        responseData={modalResponse}
        theme={theme}
      />
    </SatelliteDataContext.Provider>
  );
};

SatelliteDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SatelliteDataContext;
