import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import '../../../assets/scss/components/ui/modal/ResponseModal.scss';

const ResponseModal = ({ 
  open, 
  onClose, 
  responseData, 
  autoCloseTime = 15,
  theme = 'light'
}) => {
  const [timeRemaining, setTimeRemaining] = useState(autoCloseTime);
  const [isPaused, setIsPaused] = useState(false);

  // Manejador para el cierre automático del modal
  const handleAutoClose = useCallback(() => {
    if (timeRemaining === 0) {
      onClose();
    }
  }, [timeRemaining, onClose]);

  // Sistema de cuenta regresiva con manejo de pausa
  useEffect(() => {
    let timer;
    if (open && !isPaused && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [open, isPaused, timeRemaining]);

  // Efecto para manejar el cierre automático
  useEffect(() => {
    handleAutoClose();
  }, [timeRemaining, handleAutoClose]);

  // Reset timer when modal opens
  useEffect(() => {
    if (open) {
      setTimeRemaining(autoCloseTime);
      setIsPaused(false);
    }
  }, [open, autoCloseTime]);

  if (!open) return null;

  // Renderizado condicional del contenido de duplicados
  const renderDuplicateContent = () => {
    if (!responseData?.data?.duplicateRecords || 
        Object.keys(responseData.data.duplicateRecords).length === 0) {
      return null;
    }

    return (
      <div className={`modal-duplicate-records ${theme}`}>
        <h3 className="duplicate-title">Registros Duplicados Encontrados:</h3>
        <div className="duplicate-list">
          {Object.entries(responseData.data.duplicateRecords).map(([placa, fechas]) => (
            <div key={placa} className="duplicate-item">
              <div className="duplicate-placa">Placa: {placa}</div>
              <div className="duplicate-dates">
                Fechas duplicadas: {fechas.join(', ')}
              </div>
              <div className="duplicate-divider"></div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Renderizado de archivos no procesados
  const renderInvalidFiles = () => {
    if (!responseData?.invalidFiles || responseData.invalidFiles.length === 0) {
      return null;
    }

    return (
      <div className={`modal-invalid-files ${theme}`}>
        <h3 className="invalid-title">Archivos no procesados:</h3>
        <div className="invalid-list">
          {responseData.invalidFiles.map((file, index) => (
            <div key={index + 1} className="invalid-item">
              <div className="invalid-name">{file.fileName}</div>
              <div className="invalid-reason">{file.reason || "Error desconocido"}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Renderizado de archivos procesados
  const renderProcessedFiles = () => {
    if (!responseData?.data?.processedArchives || 
        responseData.data.processedArchives.length === 0) {
      return null;
    }

    return (
      <div className={`modal-processed-files ${theme}`}>
        <h3 className="processed-title">Archivos procesados correctamente:</h3>
        <div className="processed-count">
          Total: {responseData.data.processedArchives.length} archivos
        </div>
      </div>
    );
  };

  return (
    <div className={`response-modal-overlay ${theme}`}>
      <div className={`response-modal-container ${theme}`}>
        <div className={`response-modal-header ${theme}`}>
          <h2 className="modal-title">Resultado del Procesamiento</h2>
          <div className="modal-controls">
            <button 
              className="modal-timer-control"
              onClick={() => setIsPaused(!isPaused)}
              title={isPaused ? "Reanudar temporizador" : "Pausar temporizador"}
            >
              {isPaused ? (
                <i className="fas fa-play"></i>
              ) : (
                <i className="fas fa-pause"></i>
              )}
            </button>
            <span className="modal-timer">
              Cerrando en: {timeRemaining}s
            </span>
            <button 
              className="modal-close"
              onClick={onClose}
              title="Cerrar"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div className={`response-modal-body ${theme}`}>
          <div className="modal-message">
            {responseData?.message || "Procesamiento completado"}
          </div>
          
          {renderDuplicateContent()}
          {renderInvalidFiles()}
          {renderProcessedFiles()}
          
          {responseData?.success === false && (
            <div className={`modal-error ${theme}`}>
              <div className="error-icon">
                <i className="fas fa-exclamation-triangle"></i>
              </div>
              <div className="error-message">
                {responseData.message || "Ocurrió un error durante el procesamiento"}
              </div>
            </div>
          )}
        </div>
        
        <div className={`response-modal-footer ${theme}`}>
          <button 
            className={`modal-button ${theme}`} 
            onClick={onClose}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

ResponseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  responseData: PropTypes.object,
  autoCloseTime: PropTypes.number,
  theme: PropTypes.oneOf(['light', 'dark']),
};

export default ResponseModal;