import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Box, Card, Grid, Typography } from "@mui/material";
import { Email, Password, Person } from "@mui/icons-material";
import "../../../assets/scss/views/Login.scss";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import ImageBackgroundMale from "../../../assets/img/personaje_masculino.png";
import LogoImage from "../../../assets/img/logotivo_v2.png";
import ImageBackgroundFemale from "../../../assets/img/personaje_femenino.png";
import LogoLoginMin from "../../../assets/svg/layout/logo_login_min.svg";
import WavesBackground from "../../../assets/svg/layout/fondo_waves_complete.svg";
import { useReCaptcha } from "../../../context/ReCaptchaContext";
import {
  useNotification,
  NotificationSeverity,
  NotificationPositions,
} from "../../../context/NotificationContext";

const LoginScreen = () => {
  const [isRecovering, setIsRecovering] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [rememberUser, setRememberUser] = useState(false);
  
  const { login, isLoading, getSavedCredentials } = useAuth();
  const { executeVerification, isLoading: isRecaptchaLoading } = useReCaptcha();
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  // Cargar credenciales guardadas al iniciar
  useEffect(() => {
    const savedCredentials = getSavedCredentials();
    if (savedCredentials) {
      setEmail(savedCredentials.email || "");
      setRememberUser(true);
    }
  }, [getSavedCredentials]);

  // Detector de viewport para cambio responsive
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 810);
    };

    // Verificación inicial
    handleResize();

    // Detector de eventos de redimensionamiento
    window.addEventListener("resize", handleResize);

    // Limpieza de listener al desmontar
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Configuración de Variantes de Animación Avanzada
  const columnVariants = {
    initial: (direction) => ({
      opacity: 0,
      x: direction === "left" ? "-100%" : "100%",
    }),
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.7,
        ease: "easeInOut",
      },
    },
    exit: (direction) => ({
      opacity: 0,
      x: direction === "left" ? "100%" : "-100%",
      transition: {
        duration: 0.7,
        ease: "easeInOut",
      },
    }),
  };

  // Variantes de animación para móvil
  const mobileCardVariants = {
    initial: {
      y: "100%",
    },
    animate: {
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
    exit: {
      y: "100%",
      transition: {
        duration: 0.5,
        ease: "easeIn",
      },
    },
  };

  // Variante de Transición de Columnas
  const gridVariants = {
    initial: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    animate: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const handleLogin = async () => {
    try {
      // Desactivar botón durante la carga
      if (isLoading || isRecaptchaLoading) return;
  
      // Para v3, la verificación es invisible y se ejecuta al momento de login
      const token = process.env.REACT_APP_ENV === 'production' 
        ? await executeVerification('login')
        : 'dummy_token_for_development';
      
      if (!token && process.env.REACT_APP_ENV === 'production') {
        showNotification({
          message: "Error en la verificación de seguridad",
          severity: NotificationSeverity.WARNING,
        });
        return;
      }
  
      // Implementar timeout para la petición
      const loginPromise = login(email, password, token, rememberUser);
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Tiempo de espera agotado')), 15000)
      );
      
      // Race entre la petición y el timeout
      const response = await Promise.race([loginPromise, timeoutPromise]);
  
      if (response.success) {
        showNotification({
          message: "¡Inicio de sesión exitoso!",
          severity: NotificationSeverity.SUCCESS,
          position: NotificationPositions.TOP_RIGHT,
          autoHideDuration: 2000,
        });
  
        navigate("/dashboard");
      }
    } catch (error) {
      showNotification({
        message: error.message || "Error al iniciar sesión",
        severity: NotificationSeverity.ERROR,
      });
    }
  };
  
  // Manejador para recuperación de cuenta
  const handleRecovery = async () => {
    try {
      // Ejecutar reCAPTCHA v3 para recuperación
      const token = process.env.REACT_APP_ENV === 'production' 
        ? await executeVerification('account_recovery')
        : 'dummy_token_for_development';
      
      if (!token && process.env.REACT_APP_ENV === 'production') {
        showNotification({
          message: "Error en la verificación de seguridad",
          severity: NotificationSeverity.WARNING,
        });
        return;
      }

      // Aquí iría la lógica de recuperación, pasando el token
      showNotification({
        message: "Se ha enviado un correo con instrucciones para recuperar tu cuenta",
        severity: NotificationSeverity.SUCCESS,
      });
    } catch (error) {
      showNotification({
        message: error.message || "Error al recuperar la cuenta",
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  // Renderizado condicional para móvil
  const renderMobileView = () => (
    <Box className="mobile-login-container">
      <div className="mobile-background">
        <img
          src={WavesBackground}
          alt="background waves"
          className="mobile-background-waves"
        />
        <div className="mobile-logo-container">
          <img src={LogoLoginMin} alt="logo" className="mobile-logo" />
          <Typography variant="h4" className="mobile-logo-text">
            COOTRANSPETROLS
          </Typography>
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          className="mobile-card"
          key={isRecovering ? "mobile-recover" : "mobile-login"}
          variants={mobileCardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <div className="mobile-form-container">
            <Typography variant="h5" className="mobile-form-title">
              {isRecovering ? "Recuperacón de cuenta" : "Iniciar sesión"}
            </Typography>

            {isRecovering ? (
              // Formulario de recuperación actualizado
              <div className="form-content">
                <div className="input-wrapper">
                  <input
                    type="email"
                    placeholder="Correo"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-input"
                  />
                  <div className="input-icon">
                    <Email />
                  </div>
                </div>
                
                <div className="info-text">
                  Se enviará un mensaje con instrucciones al correo registrado.
                </div>
                
                {/* Se eliminó el componente ReCAPTCHA visible aquí */}
                
                <button 
                  className="form-button" 
                  onClick={handleRecovery}
                  disabled={isLoading || isRecaptchaLoading}
                >
                  {isRecaptchaLoading ? "Verificando..." : "Enviar"}
                </button>
                
                <div 
                  className="form-link" 
                  onClick={() => setIsRecovering(false)}
                >
                  Volver a iniciar sesión
                </div>
              </div>
            ) : (
              // Formulario de login actualizado
              <div className="form-content">
                <div className="input-wrapper">
                  <input
                    type="text"
                    placeholder="Usuario o correo"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-input"
                  />
                  <div className="input-icon">
                    <Person />
                  </div>
                </div>
                
                <div className="input-wrapper">
                  <input
                    type="password"
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-input"
                  />
                  <div className="input-icon">
                    <Password />
                  </div>
                </div>
                
                <label className="remember-me">
                  <input 
                    type="checkbox" 
                    className="checkbox" 
                    checked={rememberUser}
                    onChange={(e) => setRememberUser(e.target.checked)}
                  />
                  <span>Recordar datos de usuario</span>
                </label>
                
                {/* Se eliminó el componente ReCAPTCHA visible aquí */}
                
                <button 
                  className="form-button" 
                  onClick={handleLogin}
                  disabled={isLoading || isRecaptchaLoading}
                >
                  {isLoading ? "Iniciando..." : isRecaptchaLoading ? "Verificando..." : "Iniciar sesión"}
                </button>
                
                <div 
                  className="form-link" 
                  onClick={() => setIsRecovering(true)}
                >
                  Olvidé mi contraseña
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    </Box>
  );

  // Versión actualizada para escritorio
  const renderDesktopView = () => (
    <Card className="login-card">
      <motion.div
        className={`grid-container ${isRecovering ? "recovering" : ""}`}
        variants={gridVariants}
        initial="initial"
        animate="animate"
      >
        <AnimatePresence mode="wait">
          <Grid
            container
            key={isRecovering ? "recover-layout" : "login-layout"}
          >
            {/* Columna Dinámica de Mensaje */}
            <Grid
              item
              xs={6}
              className={`login-message ${
                isRecovering ? "recover-mode" : "login-mode"
              }`}
              component={motion.div}
              variants={columnVariants}
              custom={isRecovering ? "left" : "right"}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              {isRecovering ? (
                // Mensaje de Recuperación
                <>
                  <Typography variant="h5" className="title-shadow">
                    ¡Oh! No...
                  </Typography>
                  <div className="background-image-movement"></div>
                  <div className="logo-containerv2">
                    <img
                      src={LogoImage}
                      alt="logo"
                      className="logo-image"
                    />
                  </div>
                  <div className="background-image-character">
                    <img
                      src={ImageBackgroundFemale}
                      alt="background character"
                    />
                  </div>
                </>
              ) : (
                // Mensaje de Login
                <>
                  <Typography variant="h2" className="title-shadow">
                    Bienvenido de Nuevo
                  </Typography>
                  <div className="background-image-movement"></div>
                  <div className="logo-container">
                    <img
                      src={LogoImage}
                      alt="logo"
                      className="logo-image"
                    />
                  </div>
                  <div className="background-image-character">
                    <img src={ImageBackgroundMale} alt="background character" />
                  </div>
                </>
              )}
            </Grid>

            {/* Columna Dinámica de Contenido Principal */}
            <Grid
              item
              xs={6}
              className={`login-content ${
                isRecovering ? "recover-mode" : "login-mode"
              }`}
              component={motion.div}
              variants={columnVariants}
              custom={isRecovering ? "right" : "left"}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              {isRecovering ? (
                // Formulario de Recuperación actualizado
                <div className="content-wrapper">
                  <Typography variant="h5" gutterBottom className="form-title">
                    Recuperacón de cuenta
                  </Typography>
                  
                  <div className="form-content">
                    <div className="input-wrapper">
                      <input
                        type="email"
                        placeholder="Correo"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-input"
                      />
                      <div className="input-icon">
                        <Email />
                      </div>
                    </div>
                    
                    <div className="info-text">
                      Se enviará un mensaje con instrucciones al correo registrado.
                    </div>
                    
                    {/* Se eliminó el componente ReCAPTCHA visible aquí */}
                    
                    <button 
                      className="form-button" 
                      onClick={handleRecovery}
                      disabled={isLoading || isRecaptchaLoading}
                    >
                      {isRecaptchaLoading ? "Verificando..." : "Enviar"}
                    </button>
                    
                    <div 
                      className="form-link" 
                      onClick={() => setIsRecovering(false)}
                    >
                      Volver a iniciar sesión
                    </div>
                  </div>
                </div>
              ) : (
                // Formulario de Login actualizado
                <div className="content-wrapper">
                  <Typography variant="h5" gutterBottom className="form-title">
                    Iniciar sesión
                  </Typography>
                  
                  <div className="form-content">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        placeholder="Usuario o correo"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-input"
                      />
                      <div className="input-icon">
                        <Person />
                      </div>
                    </div>
                    
                    <div className="input-wrapper">
                      <input
                        type="password"
                        placeholder="Contraseña"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-input"
                      />
                      <div className="input-icon">
                        <Password />
                      </div>
                    </div>
                    
                    <label className="remember-me">
                      <input 
                        type="checkbox" 
                        className="checkbox" 
                        checked={rememberUser}
                        onChange={(e) => setRememberUser(e.target.checked)}
                      />
                      <span>Recordar datos de usuario</span>
                    </label>
                    
                    {/* Se eliminó el componente ReCAPTCHA visible aquí */}
                    
                    <button 
                      className="form-button" 
                      onClick={handleLogin}
                      disabled={isLoading || isRecaptchaLoading}
                    >
                      {isLoading ? "Iniciando..." : isRecaptchaLoading ? "Verificando..." : "Iniciar sesión"}
                    </button>
                    
                    <div 
                      className="form-link" 
                      onClick={() => setIsRecovering(true)}
                    >
                      Olvidé mi contraseña
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </AnimatePresence>
      </motion.div>
    </Card>
  );

  return (
    <Box className={`login-screen ${isMobile ? "mobile" : ""}`}>
      <AnimatePresence mode="wait">
        {isMobile ? renderMobileView() : renderDesktopView()}
      </AnimatePresence>
    </Box>
  );
};

export default LoginScreen;