// context/ReCaptchaContext.js
import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNotification, NotificationSeverity } from './NotificationContext';

const ReCaptchaContext = createContext(null);

export const ReCaptchaProvider = ({ children }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const observerRef = useRef(null);
  const { showNotification } = useNotification();
  
  // Limpiar cualquier mensaje de error de reCAPTCHA y mostrar notificación personalizada
  useEffect(() => {
    // Función para detectar y eliminar mensajes de error de reCAPTCHA
    const setupErrorDetection = () => {
      // Eliminar observador anterior si existe
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      
      observerRef.current = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
          if (mutation.type === 'childList') {
            for (const node of mutation.addedNodes) {
              if (node.nodeType === Node.TEXT_NODE && 
                  node.textContent?.includes('No se ha podido establecer conexión con el servicio reCAPTCHA')) {
                
                // Eliminar nodo de texto
                if (node.parentNode) {
                  node.parentNode.removeChild(node);
                }
                
                // Notificar solo una vez
                if (!networkError) {
                  setNetworkError(true);
                  showNotification({
                    message: "Error de conexión con el servicio de verificación. Verifique su red.",
                    severity: NotificationSeverity.WARNING,
                    autoHideDuration: 6000
                  });
                }
              }
            }
          }
        }
      });
      
      // Observar todo el documento
      observerRef.current.observe(document.body, { 
        childList: true,
        subtree: true,
        characterData: true
      });
      
      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
          observerRef.current = null;
        }
      };
    };
    
    const cleanup = setupErrorDetection();
    return cleanup;
  }, [networkError, showNotification]);
  
  // Implementación mejorada con reintentos y manejo de red
  const executeVerification = useCallback(async (action = 'default') => {
    if (!executeRecaptcha) return null;
    
    try {
      setIsLoading(true);
      setNetworkError(false);
      
      // Verificar conectividad antes de intentar
      if (!navigator.onLine) {
        throw new Error('Sin conexión a internet');
      }
      
      const token = await Promise.race([
        executeRecaptcha(action),
        // Timeout para evitar bloqueos indefinidos
        new Promise((_, reject) => 
          setTimeout(() => reject(new Error('Tiempo de espera agotado')), 10000)
        )
      ]);
      
      return token;
    } catch (error) {
      console.error('Error en verificación reCAPTCHA:', error);
      
      // Determinar tipo de error
      if (!navigator.onLine || error.message.includes('conexión')) {
        setNetworkError(true);
        showNotification({
          message: "Error de red. Verifique su conexión a internet.",
          severity: NotificationSeverity.WARNING
        });
      } else {
        showNotification({
          message: "Error en la verificación de seguridad. Intente más tarde.",
          severity: NotificationSeverity.ERROR
        });
      }
      
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [executeRecaptcha, showNotification]);
  
  return (
    <ReCaptchaContext.Provider value={{ 
      executeVerification, 
      isLoading, 
      networkError,
      resetNetworkError: () => setNetworkError(false)
    }}>
      {children}
    </ReCaptchaContext.Provider>
  );
};

export const useReCaptcha = () => {
  const context = useContext(ReCaptchaContext);
  if (!context) {
    throw new Error('useReCaptcha debe usarse dentro de un ReCaptchaProvider');
  }
  return context;
};